<template>
  <div class="correctInfoItem">
    <div class="item-page">
      <img
        class="item-page-icon"
        src="@/assets/imgs/bookCorrect/answer-hot.png"
      />
      <div class="item-page-title">
        {{ index }}.{{ answer.pageContent || "" }}
      </div>
    </div>
    <answer
      :answerType="0"
      :answerContent="answer.errorContent"
      @seeAnswerDetail="seeAnswerDetail"
    ></answer>
    <answer
      :answerType="1"
      :answerContent="answer.correctContent"
      @seeAnswerDetail="seeAnswerDetail"
    ></answer>
    <div class="line" :style="lineStyle"></div>
  </div>
</template>

<script>
import Answer from "./correctAnswer.vue";

export default {
  components: {
    Answer,
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    isHideLine: {
      type: Boolean,
      default: false,
    },
    answer: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    lineStyle() {
      if (this.isHideLine) {
        return "background-color: transparent;";
      }
      return "background-color: #ececec;";
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 查看答案详情
     */
    seeAnswerDetail(answerContent) {
      this.$emit("seeAnswerDetail", answerContent);
    },
  },
};
</script>

<style scoped>
.correctInfoItem {
  padding-top: 40px;
}
.item-page {
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
}
.item-page-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  margin-top: 7px;
}
.item-page-title {
  font-size: 26px;
  font-weight: 500;
  color: #333333;
  line-height: 32px;
  height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
}
.line {
  margin-top: 40px;
  margin-left: 28px;
  margin-right: 28px;
  height: 1px;
}
</style>
