<template>
  <div class="topProdInfo">
    <div class="prodInfo">
      <img class="prodInfo-img" :src="prodDetail.pic" />
      <div class="prodInfo-detail">
        <div class="prodInfo-detail-title">
          {{ prodDetail.prodName }}
        </div>
        <div class="prodInfo-detail-count">
          本书共有<span class="prodInfo-detail-count-keyword"
            >【{{ prodDetail.corrigendumNum || 0 }}】</span
          >处勘误信息
        </div>
      </div>
    </div>
    <div class="prodInfo-space"></div>
  </div>
</template>

<script>
export default {
  props: {
    prodDetail: {},
  },
};
</script>

<style scoped>
.topProdInfo {
  width: 100%;
  background-color: #fff;
}
.prodInfo {
  display: flex;
  flex-direction: row;
  padding: 28px;
}
.prodInfo-img {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
}
.prodInfo-detail {
  padding-left: 28px;
  padding-right: 0px;
  width: calc(100% - 244px);
  height: 160px;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.prodInfo-detail-title {
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
  letter-spacing: 1px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}

.prodInfo-detail-count {
  margin-top: auto;
  font-size: 28px;
  color: #666666;
  line-height: 32px;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.prodInfo-detail-count-keyword {
  font-size: 28px;
  color: #f01414;
  line-height: 32px;
  letter-spacing: 1px;
}

.prodInfo-space {
  width: 100%;
  height: 14px;
  background-color: #f5f6f9;
}
</style>
