<template>
  <div class="answerDetail">
    <div v-html="html" class="h5-html" @click="previewImage($event)"></div>
    <wx-inner-config ref="wxInnerConfig" v-if="isInWeixin"></wx-inner-config>
  </div>
</template>

<script>
import { praseFormatHtml, addImageFromRichText, isWeixin } from "@/utils/utils";
import { ImagePreview } from "vant";
import WxInnerConfig from "@/components/wxInnerConfig";

export default {
  components: {
    [ImagePreview.name]: ImagePreview,
    WxInnerConfig,
  },
  props: {
    detail: {
      type: String,
      default: "",
    },
  },
  computed: {
    isInWeixin() {
      return isWeixin();
    },
  },
  data() {
    return {
      html: "",
      richImages: [],
    };
  },
  mounted() {
    [this.html, this.richImages] = addImageFromRichText(
      praseFormatHtml(this.detail)
    );
  },
  methods: {
    // 富文本预览图片
    previewImage(e) {
      if (e.target.tagName == "IMG") {
        let currentIndex = e.target.src.split("random=")[1] - 1;
        if (isWeixin()) {
          this.$refs.wxInnerConfig.previewImage(
            this.richImages[currentIndex] || "",
            this.richImages
          );
        } else {
          ImagePreview({
            images: this.richImages,
            startPosition: currentIndex,
            closeOnPopstate: true, //页面回退关闭预览
            overlayStyle: {
              "background-color": "#000",
            },
          });
        }
      }
    },
  },
};
</script>

<style>
.answerDetail {
  width: 100%;
  background: #fff;
  padding-top: 12px;
  padding-bottom: 30px;
}
.h5-html {
  text-align: left;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 28px;
  color: #333333;
  word-wrap: break-word;
}
</style>
