<template>
  <kk-container
    :loadMoreStatus="loadMoreStatus"
    :loadMoreMarginTop="2"
    :isShowEmpty="isShowEmpty"
    :isShowLoading="isShowLoading"
    :emptyTitle="emptyTitle"
    :isBookCorrectEmpty="true"
    backgroundColor="#ffffff"
    :emptyTopOffset="84"
    @scrollToBottom="scrollToBottom"
  >
    <template slot="main">
      <div ref="KKContainerMain">
        <top-prod-info
          :prodDetail="prodDetail"
          v-if="prodDetail.prodName"
        ></top-prod-info>
        <div v-for="(value, index) in correctList" :key="index">
          <correct-info-item
            :index="index + 1"
            :answer="value"
            :isHideLine="index === correctList.length - 1"
            @seeAnswerDetail="seeAnswerDetail"
          ></correct-info-item>
        </div>
        <van-action-sheet
          v-model="showResultDetail"
          title="全部内容"
          close-icon="https://oss.kuke99.com/img/bookCorrect/close.png"
        >
          <answer-detail
            v-if="showResultDetail"
            :detail="answerDetail"
          ></answer-detail>
        </van-action-sheet>
        <wx-inner-config v-if="isInWeixin"></wx-inner-config>
      </div>
    </template>
  </kk-container>
</template>

<script>
import KkContainer from "@/components/KkContainer";
import TopProdInfo from "./components/correctTopProdInfo.vue";
import CorrectInfoItem from "./components/correctInfoItem.vue";
import AnswerDetail from "./components/answerDetail.vue";
import { getCorrigendumById } from "@/api/book";
import { ActionSheet } from "vant";
import WxInnerConfig from "@/components/wxInnerConfig";
import { isWeixin } from "@/utils/utils";
var wx = require("weixin-js-sdk");
export default {
  components: {
    KkContainer,
    TopProdInfo,
    CorrectInfoItem,
    AnswerDetail,
    [ActionSheet.name]: ActionSheet,
    WxInnerConfig,
  },
  computed: {
    isInWeixin() {
      return isWeixin();
    },
  },
  data() {
    return {
      current: 1,
      pages: 0,
      isShowEmpty: false,
      loadMoreStatus: "",
      isShowLoading: false,
      emptyTitle: "本书暂无错误内容哦～",
      prodId: "",
      requestCorrectDetailFinished: true,
      prodDetail: {},
      correctList: [],
      showResultDetail: false,
      answerDetail: "",
    };
  },
  mounted() {
    window.scroll(0, 0);
    this.prodId = this.$route.query.prodId;
    this.getResultDetail(true);
    if (isWeixin()) {
      wx.ready(function () {
        const title = "库课图书_图书勘误详情";
        const desc = "库课图书_图书勘误详情";
        const imgUrl = "https://oss.kuke99.com/img/book/share/logo.png";
        //// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后
        wx.updateTimelineShareData({
          title: title, // 分享标题
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: imgUrl, // 分享图标
          success: function () {},
        });
        wx.updateAppMessageShareData({
          title: title, // 分享标题
          desc: desc || "", // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: imgUrl, // 分享图标
          success: function () {},
        });
      });
      wx.error(function () {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    }
  },
  beforeDestroy() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f5f6f7");
  },
  methods: {
    /**
     * 获取答案详情
     */
    async getResultDetail(isLoading) {
      if (isLoading) {
        this.isShowLoading = true;
      }
      this.isShowEmpty = false;
      let param = {
        prodId: this.prodId || "",
        current: this.current,
        size: 10,
      };
      this.requestCorrectDetailFinished = false;
      let result = await getCorrigendumById(param);
      this.isShowLoading = false;
      this.requestCorrectDetailFinished = true;
      if (result) {
        this.current++;
        this.pages = result.pages;
        this.correctList.push(...result.records);
        if (result.prodName && !this.prodDetail.prodName) {
          this.prodDetail.prodName = result.prodName;
        }
        if (result.pic && !this.prodDetail.pic) {
          this.prodDetail.pic = result.pic;
        }
        this.prodDetail.corrigendumNum = result.corrigendumNum;
        if (this.current > this.pages) {
          // 到达底部
          setTimeout(() => {
            let contentHeihgt = window.getComputedStyle(
              this.$refs.KKContainerMain
            ).height;
            if (parseInt(contentHeihgt) > window.screen.height) {
              this.loadMoreStatus = "no-more";
            } else {
              this.loadMoreStatus = "";
            }
          }, 30);
        } else {
          this.loadMoreStatus = "";
        }
      } else {
        this.loadMoreStatus = "";
      }
      if (this.correctList.length == 0) {
        this.isShowEmpty = true;
      }
    },
    /**
     * 滚动到底部
     */
    scrollToBottom() {
      if (this.current <= this.pages) {
        this.loadMoreStatus = "loading";
        if (this.requestCorrectDetailFinished) {
          this.getResultDetail(false);
        }
      }
    },
    /**
     * 查看答案详情
     */
    seeAnswerDetail(answerContent) {
      this.answerDetail = answerContent;
      this.showResultDetail = true;
    },
  },
};
</script>

<style scoped>
.van-action-sheet__header {
  height: 104px;
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  line-height: 104px;
}
.van-action-sheet__close {
  padding-right: 36px;
}
.van-icon__image {
  width: 32px;
  height: 32px;
  padding-top: 32px;
  padding-right: -32px;
}
</style>
