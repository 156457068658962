<template>
  <div class="correctAnswer" :style="correctAnswerStyle">
    <div class="answer-title" :style="answerTitleStyle">{{ answerTitle }}</div>
    <div @click="previewImage($event)">
      <vue-ellipsis
        class="answer-content"
        :text="formatHtml()"
        :visibleHeight="170"
        use-inner-html
      >
        <template slot="ellipsisNode">
          <div class="answer-content-seeAll" @click="seeAll">
            <span>查看全部</span>
          </div>
        </template>
      </vue-ellipsis>
    </div>
    <wx-inner-config ref="wxInnerConfig" v-if="isInWeixin"></wx-inner-config>
  </div>
</template>

<script>
import { praseFormatHtml, addImageFromRichText, isWeixin } from "@/utils/utils";
import { VueEllipsis } from "vue-ellipsis-component";
import { ImagePreview } from "vant";
import WxInnerConfig from "@/components/wxInnerConfig";

export default {
  components: { VueEllipsis, [ImagePreview.name]: ImagePreview, WxInnerConfig },
  props: {
    answerType: {
      type: Number,
      default: 0,
    },
    answerContent: {
      type: String,
      default: "",
    },
  },
  computed: {
    isInWeixin() {
      return isWeixin();
    },
    correctAnswerStyle() {
      if (this.answerType == 0) {
        // 错误的答案
        return "border: 2px solid #feeef0;";
      } else if (this.answerType == 1) {
        // 正确的答案
        return "border: 2px solid #E3F9F6;";
      }
      return "";
    },
    answerTitleStyle() {
      if (this.answerType == 0) {
        // 错误的答案
        return "background-color:#FEEEF0; color: #F0484A";
      } else if (this.answerType == 1) {
        // 正确的答案
        return "background-color:#E3F9F6; color: #05C794;";
      }
      return "";
    },
    answerTitle() {
      if (this.answerType == 0) {
        // 错误的答案
        return "错误";
      } else if (this.answerType == 1) {
        // 正确的答案
        return "正确";
      }
      return "";
    },
  },
  data() {
    return {
      html: "",
      richImages: [],
    };
  },
  methods: {
    seeAll() {
      this.$emit("seeAnswerDetail", this.answerContent);
    },
    formatHtml() {
      [this.html, this.richImages] = addImageFromRichText(
        praseFormatHtml(this.answerContent, 109)
      );
      return this.html;
    },
    // 富文本预览图片
    previewImage(e) {
      if (e.target.tagName == "IMG") {
        let currentIndex = e.target.src.split("random=")[1] - 1;
        if (isWeixin()) {
          this.$refs.wxInnerConfig.previewImage(
            this.richImages[currentIndex] || "",
            this.richImages
          );
        } else {
          ImagePreview({
            images: this.richImages,
            startPosition: currentIndex,
            closeOnPopstate: true, //页面回退关闭预览
            overlayStyle: {
              "background-color": "#000",
            },
          });
        }
      }
    },
  },
};
</script>

<style>
.correctAnswer {
  margin-left: 28px;
  margin-right: 28px;
  border: 2px solid #feeef0;
}
.answer-title {
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.answer-content {
  margin: 28px 20px;
  text-align: left;
  font-size: 28px;
  color: #333333;
  word-wrap: break-word;
}
.answer-content-seeAll {
  font-size: 28px;
  color: #2470f7;
  line-height: 34px;
  letter-spacing: 1px;
  text-align: center;
}
</style>
